import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../hooks/useIsMobile';
import Carousel from './Carousel';
import SuccessfulRegistration from './SuccessfulRegistration';
import { Footer } from './shared/footer/Footer';

const Success = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <div>
      <div
        className={`${
          isMobile ? 'flex flex-col mx-2 justify-center' : 'grid grid-cols-2 justify-items-end'
        } items-center  py-8 `}
      >
        <SuccessfulRegistration />
        {!isMobile ? <Carousel /> : <Footer />}
      </div>
    </div>
  );
};

export default Success;
