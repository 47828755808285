import React from 'react';
import { Link } from 'react-router-dom';
import { IActivities } from '../../modals/Activities';
import dayjs from 'dayjs';
import { useIsMobile } from '../../hooks/useIsMobile';

const ActivitiesCard = ({ plannedActivities }: { plannedActivities: IActivities[] }) => {
  const isMobile = useIsMobile();
  const sortedActivities = [...plannedActivities]
    .filter((item) => item.status !== 'DELETED' && item.cancelled !== true)
    .sort((a, b) => {
      return dayjs(a.datetimeStart).isAfter(dayjs(b.datetimeStart)) ? 1 : -1;
    });
  return (
    <>
      <div className="home-card-container p-6 flex flex-col justify-between bg-white">
        <div className={`${isMobile ? 'mb-4' : ''}`}>
          <h4 className="h4 text-tertiary pb-5">Gepland</h4>

          {sortedActivities.length > 0 && (
            <div className="flex-col space-y-6">
              {sortedActivities.slice(0, 3).map((item, id) => (
                <div key={id}>
                  <Link to={`/activities/${item.id}`}>
                    <p className="note-text-light text-baseBlue500">
                      {dayjs(item?.datetimeStart).format('D MMMM YYYY')}
                    </p>
                    <p className="regular-text text-tertiary">{item?.name}</p>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {!sortedActivities.length && (
            <div className="flex-col space-y-6">
              <div>
                <p className="regular-text text-tertiary">
                  Je hebt nog geen activiteiten gepland. Maak een leuke activiteit aan, want de kluppers staan er op te
                  wachten 🥳
                </p>
              </div>
            </div>
          )}
        </div>

        <Link to={`${sortedActivities.length > 0 ? '/activities' : '/support'}`}>
          <div>
            <div className="flex items-center">
              <i className="fa-solid fa-arrow-right pr-2 text-baseBlue500" />
              <p className="text-baseBlue500 font-bold text-lg">
                {sortedActivities.length > 0
                  ? `Bekijk alle ${sortedActivities.length} activiteiten`
                  : 'Hier vind je nog meer tips'}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ActivitiesCard;
