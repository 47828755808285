import React, { useEffect, useState } from 'react';
import Hero from '../Hero';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Message } from './messages.model';
import { MessageItem } from './messageItem';
import { markNotificationsAsRead } from './messages.service';

const ListMessages = () => {
  const isMobile = useIsMobile();
  const [readMessages, setReadMessages] = useState<any[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);
  const [notificationIds, setNotificationIds] = useState<number[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCheckboxChange = (id: number, isChecked: boolean) => {    
    if (isChecked) {
      setNotificationIds((prevState) => [...prevState, id]);
    } else {
      setNotificationIds((prevState) => prevState.filter((item) => item !== id));
    }
  };

  const uncheckAll = () => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = false;
    });
  };

  const getReadMessages = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/notification/read`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'get',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          setReadMessages(response.data.content);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const getUnreadMessages = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/notification/unread`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'get',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          setUnreadMessages(response.data.content);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    getUnreadMessages();
    getReadMessages();
  }, []);

  const markAsRead = async () => {
    const response = await markNotificationsAsRead({ notificationIds });

    if (response.success) {
      uncheckAll();
      setNotificationIds([]);
      getUnreadMessages();
      getReadMessages();
    } else {
      // TODO when refactor of axios/services
    }
  };

  const deleteSingleMessage = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/notification`;

    try {
      axios.defaults.withCredentials = true;

      await axios.request({
        method: 'delete',
        url: url,
        data: {
          notificationIds,
        },
        headers: { 'Content-Type': 'application/json' },
      });

      uncheckAll();
      getUnreadMessages();
      getReadMessages();
      setNotificationIds([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
    }
  };

  const handleMessageClick = (item: Message, markAsRead?: boolean) => {
    if (item?.inAppRedirection?.entityId && item?.inAppRedirection?.entityType === 'ACTIVITY') {
      navigate(`/activities/${item?.inAppRedirection?.entityId}`, {
        state: { notificationId: markAsRead ? item.id : undefined },
      });
    }
  };

  return (
    <div>
      {/* Hero Image */}
      <Hero
        image="Fotografie-Vitality-Experience-62.png"
        heading="Meldingen"
        place="Vitality Experience 2022, Amsterdam"
      />

      <div className={`${isMobile ? 'flex flex-col p-6' : 'px-[4rem] py-[2rem]'}`}>
        <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[580px]'} h-auto rounded-xl my-4`}>
          <h4 className="h4 text-tertiary mb-6">
            Ongelezen <span className="regular-text text-tertiary"> ({unreadMessages.length})</span>
          </h4>

          {/* Toggle radio button */}
          {/*
            <div className="flex w-full items-center my-4">
              <div className="toggle flex">
                <label htmlFor="one">
                  <input id="one" type="checkbox" name="no_end_time"/>
                </label>
              </div>
              <span className="regular-text text-tertiary ml-3">Toon alleen als ik genoemd word</span>
            </div>
            */}

          <div className="flex-col space-y-2">
            {unreadMessages.length > 0 &&
              unreadMessages.map((item, id: number) => (
                <MessageItem
                  key={id}
                  item={item}
                  handleCheckboxChange={handleCheckboxChange}
                  handleMessageClick={() => handleMessageClick(item, true)}
                  unread={true}
                />
              ))}
          </div>

          <h4 className="h4 text-tertiary my-8">Gelezen</h4>

          {/*
            <div className="flex-col space-y-6 mb-6">
              {readMessages.length > 0 && readMessages.map((item, id: number) => (
                  <div key={id} className="flex gap-x-2">
                    <div className="flex items-center mb-4">
                      <input id="checkbox-2" aria-describedby="checkbox-2" type="checkbox"
                             className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded cursor-pointer"/>
                      <label htmlFor="checkbox-2" className="text-sm ml-3 font-medium text-gray-900"></label>
                    </div>
                    <div className="relative">
                      <img
                          src="/images/avatar.png"
                          className="w-[46px] h-[46px]"
                      />
                      <span
                          className="absolute top-10 left-8 transform -translate-y-1/2 w-3.5 h-3.5 bg-baseStateWarning500 border-2 border-white dark:border-gray-800 rounded-full"></span>
                    </div>
                    <div>
                      <p className="bold-text text-tertiary">{item?.message}</p>
                      <p className="note-text-light text-gray04">{dayjs(item?.createDate).format('hh:mm MMMM D')}</p>
                    </div>
                  </div>
              ))}
            </div>
*/}

          <div className="flex-col space-y-2 mb-6">
            {readMessages.length > 0 &&
              readMessages.map((item, id: number) => (
                <MessageItem
                  key={id}
                  item={item}
                  handleCheckboxChange={handleCheckboxChange}
                  handleMessageClick={() => handleMessageClick(item)}
                />
              ))}
          </div>

          <div className="flex gap-x-6">
            <div
              className={`flex bold-text text-baseBlue500 items-center mb-12 cursor-pointer ${
                !(unreadMessages.length > 0) && 'pointer-events-none opacity-20 cursor-not-allowed'
              }`}
              onClick={markAsRead}
            >
              <p>{notificationIds.length > 0 ? 'Markeer als gelezen' : 'Markeer alles als gelezen'}</p>
            </div>

            <div
              className={`flex bold-text text-baseStateWarning500 items-center mb-12 cursor-pointer ${
                !(notificationIds.length > 0) && 'pointer-events-none opacity-20 cursor-not-allowed'
              }`}
              onClick={deleteSingleMessage}
            >
              <p>{notificationIds.length > 1 ? 'Verwijder meldingen' : 'Verwijder melding'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListMessages;
