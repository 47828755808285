import React from 'react';
import { ThemeProvider } from '@material-tailwind/react';
import RootSidebarLayout from './components/dashboard/RootSidebarLayout';
import ActivityInformation from './components/activities/ActivityInformation';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Dashboard from './components/dashboard/Container';
import ListActivities from './components/activities/ListActivities';
import Messages from './components/messages/Messages';
import CreateActivityForm from './components/activities/CreateActivityForm';
import Settings from './components/Settings';
import ProfileForm from './components/profile/ProfileForm';
import SuccessActivityForm from './components/activities/SuccessActivityForm';
import PrivateRoute from './PrivateRoute';
import Success from './components/Success';
import Support from './components/dashboard/Support';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const customTheme = {};
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <PrivateRoute>
          <RootSidebarLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '/',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <Dashboard />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/home',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <Dashboard />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/profile',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <ProfileForm />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/activities',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <ListActivities />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/activities/edit/:activityId',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <CreateActivityForm />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/activities/:activityId',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <ActivityInformation />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/activities/create',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <CreateActivityForm />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/activities/success',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <SuccessActivityForm />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/messages',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <Messages />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/settings',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <Settings />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
        {
          path: '/support',
          element: (
            <PrivateRoute>
              <ScrollToTop>
                <Support />
              </ScrollToTop>
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/success',
      element: <Success />,
    },
  ]);

  return (
    <ThemeProvider value={customTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
