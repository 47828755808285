import { useEffect, useState, useRef } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";

export default function Carousel() {
  let [current, setCurrent] = useState(0);
  const timer = useRef();

  let slides = [
    {
      img: "/images/578A0485.jpg",
      title: "Samen brengen we 50+ Nederland in beweging",
      subTitle: "Registreer en maak je activiteiten aan",
    },
    {
      img: "/images/578A0001.jpg",
      title: "Bereik en activeer de (lokale) Klup community",
      subTitle: "Een persoonlijke aanpak werkt het beste",
    },
    {
      img: "/images/DSC_5090.jpg",
      title: "Op een leuke en directe manier in contact met kluppers",
      subTitle: "Daar hoort een passend profiel en aanbod bij",
    },
    {
      img: "/images/578A0169.jpg",
      title: "Enthousiasme, verbinding en gezelligheid",
      subTitle: "Daar doen we het voor bij Klup!",
    },
  ];

  let previousSlide = () => {
    if (current === 0) setCurrent(slides.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  useEffect(() => {
    clearTimeout(timer.current);
    // @ts-ignore
    timer.current = setTimeout(() => {
      if (current === slides.length - 1) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    }, 5000);
  }, [current]);

  return (
      <>
        {/* Carousel START */}
        <div className="relative h-auto overflow-hidden rounded-l-3xl md:w-[100%] w-[804px]">
          <div
              className={`flex transition ease-out duration-1000`}
              style={{
                transform: `translateX(-${current * 100}%)`,
              }}
          >
            {slides.map((s, i) => {
              return <img key={i} className=" h-[904px] object-cover" src={s.img} />;
            })}
          </div>

          <div className="absolute bottom-0 py-12 flex justify-center gap-2 w-full bg-tertiary">
            <div className="flex flex-col justify-center items-center gap-3">
              <div>
                {slides.map((s, i) => {
                  return (
                      i == current && (
                          <div key={i} className="flex flex-col text-center">
                            <p className="font-bold text-2xl text-white">{s.title}</p>
                            <p className="font-semibold text-xl text-white">
                              {s.subTitle}
                            </p>
                          </div>
                      )
                  );
                })}
              </div>

              <div className="flex gap-x-2">
                {slides.map((s, i) => {
                  return (
                      <div
                          onClick={() => {
                            setCurrent(i);
                          }}
                          key={"circle" + i}
                          className={`block rounded-full w-3 h-3 cursor-pointer  ${
                              i == current ? "bg-baseYellow500" : "bg-baseTonBlue100"
                          }`}
                      ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
