import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Hero from '../Hero';
import axios, { AxiosError } from 'axios';
import { IActivities } from '../../modals/Activities';
import dayjs from 'dayjs';
import { stringTrimmer } from '../../utils/stringFormatter';
import { useIsMobile } from '../../hooks/useIsMobile';
import { CancelledChip } from './components/cancelledChip';

const ListActivities = () => {
  const isMobile = useIsMobile();
  const [activities, setActivities] = useState<IActivities[]>([]);
  const [error, setError] = useState<string | null>(null);

  const deleteActivity = async (id: number) => {
    const url = `${process.env.REACT_APP_API_URL}api/activity/${id}/cancel`;

    try {
      axios.defaults.withCredentials = true;

      await axios.request({
        method: 'post',
        url: url,

        headers: { 'Content-Type': 'application/json' },
      });

      getPartnerData();
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
    }
  };

  const getPartnerData = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_URL}api/activity/all`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'get',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          const filteredData = response.data.filter((item: IActivities) => item.status !== 'DELETED');
          setActivities(filteredData);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getPartnerData();
    };

    fetchData();
  }, [getPartnerData]);

  const activitiesList = activities
    // Sort by start datetime
    .map((activity) => ({
      ...activity,
      name: stringTrimmer(activity.name, 110),
    }))
    .sort((a, b) => {
      return new Date(a.datetimeStart).getTime() - new Date(b.datetimeStart).getTime();
    });

  return (
    <div>
      {/* Hero Image */}
      <Hero image="578A0499.jpeg" heading="Activiteiten" place="Vitality Experience 2023, Utrecht" reverse />

      <div className={`${isMobile ? 'px-6 py-6' : 'px-[4rem] py-[2rem]'}`}>
        <div
          className={`flex flex-col ${
            isMobile ? 'w-full h-[250px]' : 'w-[580px] h-[210px]'
          } bg-tertiary rounded-xl p-6 justify-between`}
        >
          <h4 className="h4 text-white">Maak een nieuwe activiteit aan</h4>
          <p className="regular-text">
            Op naar een unieke Vitality Experience 2024! Samen brengen we 50+ Nederland in beweging. 💪
          </p>
          <Link to="/activities/create">
            <div className="flex gap-x-6">
              <img src="/icons/plus_icon.svg" />
              <p className="text-baseYellow500 text-lg font-bold">Voeg nieuwe activiteit toe</p>
            </div>
          </Link>
        </div>

        <div className={`flex flex-col ${isMobile ? 'w-full p-6' : 'w-[580px] p-8'} h-auto bg-white rounded-xl my-8`}>
          <h4 className="h4 text-tertiary">Gepland</h4>
          <p className="regular-text text-tertiary my-5">
            {activitiesList.length > 0
              ? `Er staan ${activitiesList.length} activiteiten gepland.`
              : 'Je hebt nog geen geplande activiteiten. Maak er eentje aan!'}
          </p>

          <div className="flex flex-col w-full">
            {activitiesList &&
              activitiesList.map((item, i) => (
                <>
                  <div key={`${item.id}`} className="flex space-x-2">
                    <div className="w-1/6">
                      <Link to={`/activities/${item.id}`}>
                        <p className="regular-text text-baseBlue500">{dayjs(item.datetimeStart).format('D MMM')}</p>
                      </Link>
                    </div>

                    <div className="w-5/6">
                      <div className="flex space-x-6">
                        <Link to={`/activities/${item.id}`}>
                          <p className="regular-text text-tertiary justify-self-end">{item.name}</p>
                        </Link>
                        <CancelledChip cancelled={item.cancelled} />
                      </div>
                      {!item.cancelled && (
                        <div
                          className={`flex gap-x-6 mb-4  ${
                            isMobile ? 'opacity-100' : 'opacity-0 hover:opacity-100'
                          } transition-opacity duration-150`}
                        >
                          <Link to={`/activities/${item.id}`}>
                            <p className="small-caption-text cursor-pointer">Bekijk info</p>
                          </Link>
                          <Link to={`/activities/edit/${item.id}`}>
                            <p className="small-caption-text cursor-pointer">Bewerken</p>
                          </Link>
                          {/*<p className="small-caption-text cursor-pointer">Bewerken</p>*/}
                          <p
                            className="small-caption-text cursor-pointer text-baseStateWarning500"
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Je staat op het punt om je activiteit te verwijderen. Weet je het zeker?'
                                )
                              ) {
                                deleteActivity(item.id);
                              }
                            }}
                          >
                            Verwijderen
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>

          {/*
            <h4 className="h4 text-tertiary">Concept</h4>
            <p className="regular-text text-tertiary">
              Er staat 1 activiteit in concepten.
            </p>
            <div className="flex flex-col gap-y-8 my-8">
              {activities.slice(0, 2).map((item, i) => (
                  <div key={i} className="flex gap-x-8 justify-between">
                    <p className="regular-text text-baseBlue500">{dayjs(item.datetimeStart).format('D MMMM YYYY')}</p>
                    <p className="regular-text text-tertiary justify-self-end">
                      {item.name}
                    </p>
                  </div>
              ))}
            </div>
            */}
        </div>
      </div>
    </div>
  );
};

export default ListActivities;
