import React, { useEffect, useState } from 'react';
import Hero from '../Hero';
import axios, { AxiosError } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useIsMobile } from '../../hooks/useIsMobile';
import { markActivityNotificationAsRead } from './activities.service';

interface Comment {
  parentId?: number;
  activityId: number;
  comment: string;
}

const ListActivities = () => {
  const isMobile = useIsMobile();
  const [participants, setParticipants] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [parentId, setParentId] = useState<any>();
  const [activity, setActivity] = useState<any>({});
  const [comments, setComments] = useState<any[]>([]);
  const [textAreaCount, setTextAreaCount] = useState('');
  let { activityId } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  /**
   * Get Participants
   */

  const markNotificationAsRead = async (notificationId: number) => {
    await markActivityNotificationAsRead(notificationId);
  };

  useEffect(() => {
    if (location?.state?.notificationId) {
      markNotificationAsRead(location.state.notificationId);
    }

    const getParticipantsData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/activity/${activityId}/participants`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setParticipants(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getParticipantsData();
  }, []);

  /**
   * Get Activity by Id
   */
  useEffect(() => {
    const getActivityByIdData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/activity/${activityId}`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setActivity(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getActivityByIdData();
  }, []);

  /**
   * Get Comments of Activity
   */
  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/activity/${activityId}/comment`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'get',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          setComments(response.data.content);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const addComment = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/activity/comment`;

    const data: Comment = {
      activityId: +activityId!,
      comment: textAreaCount,
    };

    if (parentId) {
      data.parentId = parentId.id;
    }

    try {
      axios.defaults.withCredentials = true;

      await axios.post(url, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      setTextAreaCount('');
      setParentId(undefined);
      getComments();
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
    }
  };

  return (
    <div>
      {/* Hero Image */}
      <Hero image="578A0499.jpeg" heading="Activiteiten" place="Vitality Experience 2023, Utrecht" reverse />

      <div className={`${isMobile ? 'flex flex-col p-6' : 'px-[4rem] py-[2rem]'}`}>
        <div className="flex items-center mb-4 cursor-pointer" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-arrow-left pr-2 text-baseBlue500" />
          <p className="text-baseBlue500 font-bold text-lg">Terug naar overzicht</p>
        </div>

        <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[580px]'} h-auto rounded-xl px-6 justify-center`}>
          <h2 className="h2">{activity?.name}</h2>
          <p className="regular-text text-tertiary">
            Hier vind je deelnemers en meldingen voor deze activiteit. Je kunt vanuit deze pagina ook reageren op vragen
            van kluppers.
          </p>
        </div>

        <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[580px]'} h-auto bg-white rounded-xl p-8 my-8`}>
          <h4 className="h4 text-tertiary">
            Deelnemers
            <span className="regular-text text-tertiary"> ({participants && participants.length})</span>
          </h4>
          <p className="regular-text text-tertiary">
            Communiceer met deelnemers via de reacties in je activiteit. Het is niet mogelijk om hen een privébericht te
            sturen.
          </p>

          <div className="grid grid-cols-2 gap-y-6 my-6">
            {participants &&
              participants.map((item, i) => (
                <div key={i} className="flex gap-x-3 items-center">
                  <img src={item?.klupper?.photo ?? `images/avatar.png`} className="w-[46px] h-[46px] rounded-full" />
                  <p className="bold-text">{item?.klupper?.firstName}</p>
                </div>
              ))}
          </div>

          {participants.length > 12 && (
            <div className="flex bold-text text-baseBlue500 items-center mb-12 cursor-pointer">
              <i className="fa-solid fa-arrow-right pr-2 text-baseBlue500" />
              <p>Bekijk alle deelnemers</p>
            </div>
          )}

          <div className="relative">
            <h4 className="h4 text-tertiary">
              Reacties <span className="regular-text text-tertiary"> ({comments.length})</span>
            </h4>
            <span className="absolute top-0 left-32 transform -translate-y-1/2 w-3.5 h-3.5 bg-baseStateWarning500 border-2 border-white dark:border-gray-800 rounded-full"></span>
          </div>

          <p className="regular-text text-tertiary">
            Hieronder vind je alle reacties op deze activiteit. Een overzicht met alle andere meldingen vind je op deze
            pagina.{' '}
          </p>

          <section className="bg-white px-4 py-4 rounded-xl">
            <div className="divide-y">
              {comments &&
                comments.map((item, index) => (
                  <div
                    key={index}
                    className={`flex p-3 gap-x-4 ${item.id === parentId?.id ? 'bg-baseTonBlue100' : 'bg-white'}`}
                  >
                    <img src={item?.klupper?.photo} className="max-w-[46px] max-h-[46px] rounded-full" />
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between">
                        <p className="text-baseTonBlue900 text-[16px] font-bold">{item?.klupper?.firstName}</p>
                        <p className="small-caption-text text-grayTone700">
                          {dayjs(item?.updateDate).format('hh:mm MMMM D')}
                        </p>
                      </div>
                      <p className="regular-text text-tertiary tracking-tight leading-5 py-1">{item?.comment}</p>
                      <p
                        className="text-[14px] font-bold text-baseBlue500 cursor-pointer"
                        onClick={() => setParentId(item)}
                      >
                        Beantwoorden
                      </p>

                      {item?.children.length > 0 && <hr className="border-t border-gray-300 my-4" />}

                      {item?.children.map((childItem: any, index: number) => (
                        <div key={index} className="flex p-3 gap-x-4 self-end w-full h-auto">
                          <img src={item?.klupper?.photo} className="max-w-[46px] max-h-[46px] rounded-full" />
                          <div className="flex flex-col w-full">
                            <div className="flex justify-between">
                              <p className="text-baseTonBlue900 text-[16px] font-bold">
                                {childItem?.klupper?.firstName}
                              </p>
                              <p className="small-caption-text text-grayTone700">
                                {dayjs(childItem?.updateDate).format('hh:mm MMMM D')}
                              </p>
                            </div>
                            <p className="regular-text text-tertiary tracking-tight leading-5 py-1">
                              {childItem?.comment}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>

            <div className="flex flex-col pb-3 pt-8">
              {parentId && (
                <p className="note-text-light pl-3 pb-2 text-tertiary">Je reageert op {parentId?.klupper?.firstName}</p>
              )}

              <textarea
                id="message"
                placeholder="Typ hier je bericht"
                className="border bg-grayTone100 text-tertiary h-[102px] rounded-xl px-3 py-2 text-[17px]  w-full focus:outline-none border-1 overflow-y-hidden"
                onChange={(e) => setTextAreaCount(e.target.value)}
                value={textAreaCount}
              ></textarea>

              {textAreaCount.length ? (
                <img
                  onClick={addComment}
                  src="/icons/send_chat_available.svg"
                  className="max-w-[19px] self-end py-3 cursor-pointer"
                />
              ) : (
                <img src="/icons/send_chat_disabled.svg" className="max-w-[19px] self-end py-3 cursor-pointer" />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ListActivities;
