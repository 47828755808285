import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { IPartner } from '../../modals/interfaces';
import { getUserId } from '../../utils/helpers';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Sidebar } from './Sidebar';
import { Header } from '../header/Header';
import { Drawer } from '../Drawer';

const RootSidebarLayout = () => {
  const isMobile = useIsMobile();
  const [partner, setPartner] = useState<IPartner>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const completeProfile = partner ? partner.photo : false;
  let userId = getUserId();
  let navigate = useNavigate();
  const { state } = useLocation();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const getPartnerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/partner/${userId}`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setPartner(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getPartnerData();
  }, [state?.refreshProfile]);

  return (
    <>
      {isMobile ? (
        <>
          <Header partner={partner} onClickMenuButton={() => setIsDrawerOpen((prevState) => !prevState)} />
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Sidebar completeProfile={completeProfile} partner={partner} onClickLink={() => setIsDrawerOpen(false)} />
          </Drawer>
          <Outlet />
        </>
      ) : (
        <Sidebar completeProfile={completeProfile} partner={partner}>
          <Outlet />
        </Sidebar>
      )}
    </>
  );
};

export default RootSidebarLayout;
