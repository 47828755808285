import React from 'react';
import { useIsMobile } from '../hooks/useIsMobile';

const SuccessfulRegistration = () => {
  const isMobile = useIsMobile();
  return (
    <div>
      <section className={`${isMobile ? 'w-full' : 'h-[700px] max-w-[60%]'} container mx-auto grid content-center`}>
        <div className="mb-16">
          <h3 className="h3">Bedankt voor je registratie</h3>
          <h6 className="h6 font-[800] text-[18px] leading-normal">
            We proberen je binnen 48 uur per mail een reactie te sturen vanuit de onderstaande opties.
          </h6>
        </div>

        <div className="space-y-8">
          <div>
            <h4 className="h4 text-stateCorrectn500">Geaccepteerd</h4>
            <h6 className="h6">
              Jouw bedrijf en aanbod sluit perfect aan bij de Klup community. Je bent geaccepteerd als partner en je
              kunt aan de slag met je eerste activiteit.
            </h6>
          </div>

          <div>
            <h4 className="h4 text-stateAttention500">Wijzigen</h4>
            <h6 className="h6">
              Naar aanleiding van je registratie willen we je vragen nog eens goed naar het aanbod te kijken en dit
              beter toe te spitsen op de community.
            </h6>
          </div>

          <div>
            <h4 className="h4 text-baseStateWarning500">Geweigerd</h4>
            <h6 className="h6">
              Helaas, je registratie als partner is geweigerd. Je bedrijf en/of aanbod sluit niet aan bij Klup.
            </h6>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SuccessfulRegistration;
