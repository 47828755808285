import { NavLink } from 'react-router-dom';
import { IPartner } from '../../modals/interfaces';
import { useIsMobile } from '../../hooks/useIsMobile';

type SidebarProps = {
  partner?: IPartner;
  completeProfile: string | boolean;
  children?: React.ReactNode;
  onClickLink?: () => void;
};

export const Sidebar: React.FC<SidebarProps> = ({ partner, completeProfile, onClickLink, children }) => {
  const isMobile = useIsMobile();

  const menuItemStyle = ` font-extrabold ${
    isMobile ? 'text-2xl' : 'text-lg'
  } tracking-wide hover:text-baseTonBlue300 ease-in duration-120 cursor-pointer`;

  return (
    <div className="flex">
      <div className={`bg-baseTonBlue900 ${isMobile ? 'w-full pt-10' : 'w-[305px]'} h-screen p-6 top-0 sticky`}>
        {/* Wrapper Container */}
        <div className={`flex flex-col ${isMobile ? 'justify-center items-center' : ''} h-full`}>
          <div>
            {!isMobile && (
              <div className="flex items-center justify-center gap-x-2 cursor-pointer">
                <img className="w-[45px] h-[45px] rounded-full" src={partner?.photo} alt="partner photo" />
                <h2 className="text-white font-bold text-lg tracking-wider">{partner?.companyName || 'Partner'}</h2>
              </div>
            )}

            {/* Navigation Links */}
            <div className={`flex flex-col ${isMobile ? 'space-y-8 items-center my-4' : 'gap-y-4 pl-8 my-10'} `}>
              <NavLink to="/home" onClick={onClickLink}>
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4 hover:text-baseBlue500 cursor-pointer">
                    {!isMobile &&
                      (isActive ? (
                        <img
                          className={`${isMobile ? 'w-[45px] h-[45px]' : ''}`}
                          src="/icons-side-menu/home_24px_white_selected.svg"
                        />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/home_24px_white.svg"
                        />
                      ))}

                    <h2 className={`${menuItemStyle} ${isActive ? 'text-blue' : 'text-white'}`}>Home</h2>
                  </div>
                )}
              </NavLink>

              <NavLink
                to="/activities"
                onClick={onClickLink}
                className={`${!completeProfile && 'pointer-events-none opacity-20 cursor-not-allowed'}`}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4">
                    {!isMobile &&
                      (isActive ? (
                        <img
                          className={`${isMobile ? 'w-[45px] h-[45px]' : ''}`}
                          src="/icons-side-menu/activiteiten_24px_white_selected.svg"
                        />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/activiteiten_24px_white.svg"
                        />
                      ))}

                    <h2 className={`${isActive ? 'text-baseBlue300' : 'text-white'} ${menuItemStyle}`}>Activiteiten</h2>
                  </div>
                )}
              </NavLink>

              <NavLink
                to="/messages"
                onClick={onClickLink}
                className={`${!completeProfile && 'pointer-events-none opacity-20 cursor-not-allowed'}`}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4 hover:text-baseBlue500 cursor-pointer">
                    {!isMobile &&
                      (isActive ? (
                        <img
                          className={`${isMobile ? 'w-[45px] h-[45px]' : ''}`}
                          src="/icons-side-menu/meldingen_24px_white_selected.svg"
                        />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/meldingen_24px_white.svg"
                        />
                      ))}

                    <div className="relative">
                      <h2 className={`${isActive ? 'text-blue' : 'text-white'} ${menuItemStyle}`}>Meldingen</h2>
                      <span
                        className={`absolute top-0  ${
                          isMobile ? 'right-[-1rem]' : 'left-[5.7rem]'
                        } transform -translate-y-1/2 w-2 h-2 bg-baseStateWarning500 rounded-full`}
                      ></span>
                    </div>
                  </div>
                )}
              </NavLink>

              <NavLink to="/profile" onClick={onClickLink}>
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4 hover:text-baseBlue500 cursor-pointer">
                    {!isMobile &&
                      (isActive ? (
                        <img className="w-[45px] h-[45px] " src="/icons-side-menu/profiel_24px_white_selected.svg" />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/profiel_24px_white.svg"
                        />
                      ))}

                    <h2 className={`${isActive ? 'text-blue' : 'text-white'} ${menuItemStyle}`}>Profiel</h2>
                  </div>
                )}
              </NavLink>

              <NavLink to="/settings" onClick={onClickLink}>
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4 hover:text-baseBlue500 cursor-pointer">
                    {!isMobile &&
                      (isActive ? (
                        <img
                          className="w-[45px] h-[45px] "
                          src="/icons-side-menu/instellingen_24px_white_selected.svg"
                        />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/instellingen_24px_white.svg"
                        />
                      ))}

                    <h2 className={`${isActive ? 'text-blue' : 'text-white'} ${menuItemStyle}`}>Instellingen</h2>
                  </div>
                )}
              </NavLink>

              <NavLink to="/support" onClick={onClickLink}>
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="flex items-center justify-start gap-x-4">
                    {!isMobile &&
                      (isActive ? (
                        <img className="w-[45px] h-[45px] " src="/icons-side-menu/support_24px_white_selected.svg" />
                      ) : (
                        <img
                          className={`${isMobile ? 'w-[35px] h-[35px]' : ''}`}
                          src="/icons-side-menu/support_24px_white.svg"
                        />
                      ))}

                    <h2 className={`${menuItemStyle} ${isActive ? 'text-blue' : 'text-white'}`}>Support</h2>
                  </div>
                )}
              </NavLink>
            </div>
          </div>
          {completeProfile && (
            <NavLink to="/activities/create" onClick={onClickLink}>
              {({ isActive, isPending, isTransitioning }) => (
                <div
                  className={`${
                    isMobile ? 'w-full mx-auto mt-8' : ''
                  } flex items-center justify-center w-[225px] bg-baseYellow500 text-baseTonBlue900
       rounded-lg p-4 h-[49px] text-center cursor-pointer font-black`}
                >
                  {isActive ? <i className="fa-solid fa-plus pr-2" /> : <i className="fa-solid fa-plus pr-2" />}

                  <span className="font-extrabold">Activiteit aanmaken</span>
                </div>
              )}
            </NavLink>
          )}
          <div
            className={`${
              isMobile ? 'flex flex-1 flex-col justify-end items-center w-full' : 'max-w-[216px]'
            } mt-auto text-center`}
          >
            <div className={`${isMobile ? 'w-full flex justify-center items-center' : 'max-w-[216px]'}`}>
              <img
                className={`mb-4 self-center ${isMobile ? 'h-[45px]' : 'h-[77px]'}  rounded-lg`}
                src="/images/vitality-experience-white.png"
                alt=""
              />
            </div>
            <div className="text-center">
              <p className={`${isMobile ? 'text-xs' : 'text-sm'} text-baseTonBlue200 font-light text-center`}>
                Vitality Experience is een initiatief van Klup en Hersenstichting
              </p>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
