import axios from 'axios';

// Create an instance of axios
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});
