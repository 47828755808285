import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { InputField } from '../RegisterPartnerForm';
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Hero from '../Hero';
import axios, { AxiosError } from 'axios';
import { extractFilename, getToken, getUserId } from '../../utils/helpers';
import SearchPlace from '../SearchPlace';
import { IPartner } from '../../modals/interfaces';
import Logout from '../shared/Logout';
import { interestsItems } from './profile.constants';
import { formToProfileMapper } from './profile.mapper';
import { useIsMobile } from '../../hooks/useIsMobile';
import { SubmitButton } from '../shared/submitButton/SubmitButton';

const ProfileForm = () => {
  const isMobile = useIsMobile();
  let navigate = useNavigate();
  const token = getToken();
  const [partner, setPartner] = useState<IPartner>();
  const [isPartnerDataLoaded, setPartnersDataLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPhotoUploading, setPhotoUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /* Profile Photo */
  const handlePhotoUpload = async (selectedPhotoFile: File) => {
    if (!selectedPhotoFile) {
      console.error('No file selected.');
      return;
    }

    setPhotoUploading(true);
    const data = new FormData();
    data.append('images', selectedPhotoFile);

    const url = `${process.env.REACT_APP_URL}api/image?imageType=AVATAR`;

    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Can't set formik here since its async and now it's call on submit
      const fileName = response.data[0];
      return fileName;
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setPhotoUploading(false);
    }
  };

  /* Cover Photo */

  const handleCoverPhotoUpload = async (selectedCoverPhotoFile: File) => {
    if (!selectedCoverPhotoFile) {
      console.error('No file selected.');
      return;
    }

    const data = new FormData();
    data.append('images', selectedCoverPhotoFile);

    const url = `${process.env.REACT_APP_URL}api/image?imageType=BACKGROUND`;

    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Can't set formik here since its async and now it's call on submit
      const bgPhotoName = response.data[0];
      return bgPhotoName;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleCoverPhotoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    selectedFile && formik.setValues({ ...formik.values, bgPhoto: selectedFile.name, bgPhotoFile: selectedFile });
  };

  const handlePhotoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    selectedFile && formik.setValues({ ...formik.values, photo: selectedFile.name, photoFile: selectedFile });
  };

  const formik = useFormik({
    initialValues: {
      companyName: '',
      photo: null,
      photoFile: null,
      bgPhoto: null,
      bgPhotoFile: null,
      location: '',
      interests: [0],
      locationLat: 0,
      locationLng: 0,
      bio: '',
      phoneNumber: '',
    } as {
      companyName: string;
      photo: string | null;
      photoFile: string | File | null;
      bgPhoto: string | null;
      bgPhotoFile: string | File | null;
      location: string;
      interests: number[];
      locationLat: number;
      locationLng: number;
      bio: string;
      phoneNumber: string;
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      companyName: Yup.string().required(),
      photo: Yup.mixed().required().label('Profile photo'),
      photoFile: Yup.mixed().test('fileSize', 'De bestandsgrootte moet kleiner zijn dan 2 MB', (file: any) => {
        const isValidSize = typeof file === 'string' ? true : file?.size < 2097152;
        return isValidSize;
      }),
      bgPhotoFile: Yup.mixed().test('fileSize', 'De bestandsgrootte moet kleiner zijn dan 2 MB', (file: any) => {
        const isValidSize = typeof file === 'string' ? true : file?.size < 2097152;
        return isValidSize;
      }),
      bgPhoto: Yup.mixed().required().label('Cover photo'),
      location: Yup.string().required(),
      interests: Yup.array().of(Yup.number()).min(3).max(6).required(),
      locationLat: Yup.number().required(),
      locationLng: Yup.number().required(),
      bio: Yup.string().required(),
      phoneNumber: Yup.string().required(),
    }),
    onSubmit: () => {
      updateProfile();
    },
  });

  useEffect(() => {
    const getPartnerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/partner/${getUserId()}`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        // console.log('document.cookie: ', document.cookie);
        axios
          .request(config)
          .then((response) => {
            setPartner(response.data);
            formik.setValues({
              companyName: response.data.companyName || '',
              photo: response.data.photo || null,
              photoFile: response.data.photo || null,
              bgPhoto: response.data.bgPhoto || null,
              bgPhotoFile: response.data.bgPhoto || null,
              location: response.data.residence || '',
              interests: response.data.interests.map((item: any) => item.id) || [],
              locationLat: response.data.locationLat || 0,
              locationLng: response.data.locationLng || 0,
              bio: response.data.bio || '',
              phoneNumber: response.data.phoneNumber || '',
            });
            formik.setTouched({
              companyName: false,
              photo: false,
              photoFile: false,
              bgPhoto: false,
              bgPhotoFile: false,
              location: false,
              interests: false,
              locationLat: false,
              locationLng: false,
              bio: false,
              phoneNumber: false,
            });
            setPartnersDataLoaded(true);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (!isPartnerDataLoaded) {
      getPartnerData();
    }
  }, []);

  useEffect(() => {
    if (!isPartnerDataLoaded) {
      formik.setFieldValue('interests', []);
    }
  }, []);

  const updateProfile = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/partner`;

    try {
      setIsSaving(true);
      axios.defaults.withCredentials = true;

      let photo = formik.values.photo;
      let bgPhoto = formik.values.bgPhoto;

      if (
        formik.touched.photo &&
        formik.touched.photoFile &&
        formik.values.photoFile &&
        typeof formik.values.photoFile !== 'string'
      ) {
        photo = await handlePhotoUpload(formik.values.photoFile as File);
      }

      if (formik.touched.bgPhoto && formik.touched.bgPhotoFile && typeof formik.values.bgPhotoFile !== 'string') {
        bgPhoto = await handleCoverPhotoUpload(formik.values.bgPhotoFile as File);
      }

      const res = await axios
        .put(url, formToProfileMapper({ ...formik.values, photo, bgPhoto }), {
          headers: { 'Content-Type': 'application/json' },
        })
        .finally(() => setIsSaving(false));

      if (res) {
        navigate('/', { state: { refreshProfile: true } });
      }
    } catch (err) {
      setIsSaving(false);
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
      console.log('error: ', err);
    }
  };

  const handlePlaceChange = (selectedPlace: google.maps.places.PlaceResult) => {
    const address = selectedPlace.formatted_address;
    const geometry = selectedPlace.geometry;

    formik.setFieldValue('location', address);
    formik.setFieldValue('locationLat', geometry?.location?.lat());
    formik.setFieldValue('locationLng', geometry?.location?.lng());
  };

  // @ts-ignore
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          {/* Hero Image */}
          <Hero image="578A0575.png" heading="Profiel" place="Vitality Experience 2023, Utrecht" />

          {/* Form */}
          <div className={`${isMobile ? 'px-6 py-6' : 'px-[4rem] py-[2rem]'}`}>
            <div className={`${isMobile ? 'w-full gap-y-6' : 'w-full gap-y-5 max-w-3xl'} flex flex-col  rounded-xl `}>
              <div className={`${isMobile ? 'w-full space-y-6' : 'w-full'} `}>
                {/* Profile photo */}
                <section>
                  <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-baseline space-y-2`}>
                    <h2 className="basis-1/4 subtitle-extra-bold-text">Profielfoto &#42;</h2>
                    <div className={`${isMobile ? 'flex-col' : 'relative inline-block'} w-full`}>
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          name="photoFile"
                          className="absolute z-10 opacity-0 cursor-pointer"
                          onChange={handlePhotoChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.photoFile && formik.errors.photoFile ? (
                          <div>{formik.errors.photoFile}</div>
                        ) : null}
                        <div className="py-6 px-4 border border-accent-primary rounded-lg flex items-center justify-between">
                          <div>
                            <i className="fa-solid fa-image fa-xl pr-2 text-tertiary" />
                            {/*@ts-ignore*/}
                            <span className="text-tertiary">
                              {formik.values.photo ? extractFilename(formik.values.photo) : ''}
                            </span>
                          </div>
                          <div>
                            {partner?.photo && !formik.errors.photoFile && (
                              <i className="fa fa-check-circle text-green-500" />
                            )}
                          </div>
                        </div>
                      </label>
                      <p className="my-2 ml-3 note-text-light text-tertiary">Max 2 MB, alleen jpg of png.</p>
                    </div>
                  </div>
                </section>

                {/* Cover photo */}
                <section>
                  <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-baseline space-y-2`}>
                    <h2 className="basis-1/4 subtitle-extra-bold-text">Omslagfoto &#42;</h2>
                    <div className={`${isMobile ? 'flex-col' : 'relative inline-block'} w-full`}>
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          name="bgPhotoFile"
                          className={`absolute z-10 opacity-0 cursor-pointer`}
                          onChange={handleCoverPhotoChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.bgPhotoFile && formik.errors.bgPhotoFile ? (
                          <div>{formik.errors.bgPhotoFile}</div>
                        ) : null}
                        <div className="py-6 px-4 border border-accent-primary rounded-lg flex justify-between items-center">
                          <div>
                            <i className="fa-solid fa-image fa-xl pr-2 text-tertiary" />
                            {/*@ts-ignore*/}
                            <span className="text-tertiary">
                              {formik.values.bgPhoto ? extractFilename(formik.values.bgPhoto) : ''}
                            </span>
                          </div>
                          <div>
                            {partner?.bgPhoto && !formik.errors.bgPhotoFile && (
                              <i className="fa fa-check-circle text-green-500" />
                            )}
                          </div>
                        </div>
                      </label>
                      <p className="my-2 ml-3 note-text-light text-tertiary">Max 2 MB, alleen jpg of png.</p>
                    </div>
                  </div>
                </section>

                {/*Company Name*/}
                <section>
                  <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-baseline space-y-2`}>
                    <h2 className="basis-1/4 subtitle-extra-bold-text">Bedrijfsnaam &#42;</h2>
                    <div className="flex flex-col w-full gap-y-2">
                      <InputField
                        type="text"
                        name="companyName"
                        errorstate={formik.errors.companyName && formik.touched.companyName}
                        errorMessage={formik.errors.companyName}
                      />
                      <p className=" ml-3 note-text-light text-tertiary">
                        Als je je bedrijfsnaam aanpast, moet Klup dit eerst goedkeuren.
                      </p>
                    </div>
                  </div>
                </section>

                {/*Location*/}
                <section>
                  <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-baseline space-y-2`}>
                    <h2 className="basis-1/4 subtitle-extra-bold-text">Locatie &#42;</h2>
                    <div className="flex flex-col w-full gap-y-2">
                      <SearchPlace onPlaceChange={handlePlaceChange} initialValue={formik.values.location} />
                    </div>
                  </div>
                </section>

                {/*Bio*/}
                <section>
                  <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-baseline space-y-2`}>
                    <div className="flex flex-col basis-1/4">
                      <h2 className="subtitle-extra-bold-text">Bio &#42;</h2>
                      {/*
                      <p className="small-caption-text text-baseBlue500">
                        Toon voorbeelden
                      </p>
                      */}
                    </div>
                    <textarea
                      id="tekens"
                      name="bio"
                      value={formik.values.bio ? formik.values.bio : ''}
                      className="border border-accent-primary text-tertiary h-[129px] rounded-xl py-4 px-4 text-[17px]  w-full focus:outline-none border-1 overflow-y-visible"
                      placeholder="Beschrijf wie jullie zijn (maak het persoonlijk) en wat jullie aanbieden"
                      onChange={(event) => {
                        formik.setFieldValue('bio', event.target.value);
                      }}
                    ></textarea>
                  </div>
                </section>

                {/*Interests*/}
                <section>
                  <div className={`flex w-full ${isMobile ? 'flex-col' : 'flex-row mt-6'} items-baseline space-y-2}`}>
                    <div className={`${isMobile ? 'w-full mb-4' : 'basis-1/4'}`}>
                      <h2 className="subtitle-extra-bold-text">Soort activiteiten &#42;</h2>
                      <p className="small-caption-text text-tertiary">(3 tot 6)</p>
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <div role="group" className="flex items-center flex-wrap gap-x-2 gap-y-3">
                        {interestsItems.map((item) => (
                          <label
                            key={item.id}
                            className={`cursor-pointer ${
                              formik.values.interests.includes(item.id) ? 'bg-powderBlue' : 'bg-grayTone100'
                            } px-3 py-2 font-bold rounded-3xl text-grayTone700`}
                          >
                            <Field
                              className={`peer hidden `}
                              type="checkbox"
                              name="interests"
                              value={item.id.toString()}
                              checked={formik.values.interests.includes(item.id)}
                              onBlur={formik.handleBlur}
                              onChange={(e: any) => {
                                if (e.target.checked && formik.values.interests.length < 6) {
                                  formik.setFieldValue('interests', [...formik.values.interests, item.id]);
                                } else {
                                  formik.setFieldValue(
                                    'interests',
                                    formik.values.interests.filter((interest) => interest !== item.id)
                                  );
                                }
                              }}
                            />
                            <div className="flex items-center gap-x-2">
                              {formik.values.interests.includes(item.id) ? (
                                <img src="/icons/check_circle.svg" />
                              ) : (
                                <img src="/icons/add_circle.svg" />
                              )}
                              <p className={`${formik.values.interests.includes(item.id) ? 'text-baseBlue500' : ''}`}>
                                {item.name}
                              </p>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>

                {/*Phone*/}
                <section>
                  <div
                    className={`flex ${
                      isMobile ? 'flex-col w-full' : 'flex-row  items-center'
                    } item-baseline  space-y-2`}
                  >
                    <div className={`${isMobile ? 'w-full px-2' : 'basis-1/4'}`}>
                      <h2 className=" subtitle-extra-bold-text">Telefoon &#42;</h2>
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                      <InputField
                        type="text"
                        name="phoneNumber"
                        errorstate={formik.errors.phoneNumber && formik.touched.phoneNumber}
                        errorMessage={formik.errors.phoneNumber}
                      />
                    </div>
                  </div>
                </section>
                <div className={`flex w-full gap-x-4 ${isMobile ? 'justify-center items-center' : 'justify-end '} `}>
                  <button
                    type={'button'}
                    className={`w-auto  ${isMobile ? 'w-full' : 'min-w-[189px]'}`}
                    onClick={() => navigate(-1)}
                  >
                    <div
                      className={`my-4 w-full flex justify-center items-center rounded-xl max-w-[540px] h-[58px] text-center cursor-pointer font-bold border-grayTone500 text-grayTone500 border-4`}
                    >
                      <>Annuleren</>
                    </div>
                  </button>

                  <SubmitButton
                    type="submit"
                    text="Opslaan"
                    isSaving={isSaving}
                    disabled={formik.dirty && formik.isValid && !isPhotoUploading}
                    icon={true}
                  />
                </div>
              </div>
              <Logout />
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
export default ProfileForm;
