import { useIsMobile } from '../../hooks/useIsMobile';
import { MobileLanding } from '../../pages/home/mobile';
import Carousel from '../Carousel';
import LoginForm from '../LoginForm';
import { Footer } from '../shared/footer/Footer';

const Login = () => {
  const isMobile = useIsMobile();

  // if (isMobile) {
  //   return <MobileLanding />;
  // }

  return (
    <div>
      <div className={`${isMobile ? 'flex flex-col' : 'grid grid-cols-2 py-8'} items-center justify-items-end`}>
        <LoginForm />
        {!isMobile ? <Carousel /> : <Footer />}
      </div>
    </div>
  );
};

export default Login;
