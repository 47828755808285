import RegisterPartnerForm from '../RegisterPartnerForm';
import Carousel from '../Carousel';
import { useIsMobile } from '../../hooks/useIsMobile';

const Register = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <div className={`${isMobile ? 'flex flex-col mx-2' : 'grid grid-cols-2'} items-center justify-items-end py-8 `}>
        <RegisterPartnerForm />
        {!isMobile && <Carousel />}
      </div>
    </>
  );
};

export default Register;
