import { FC } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { Spinner } from '../spinner/Spinner';

export interface SubmitButtonProps {
  disabled?: boolean;
  icon?: boolean;
  text: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  isSaving?: boolean;
  onSubmit?: () => void;
}

export const SubmitButton: FC<SubmitButtonProps> = ({ disabled, text, icon, type = 'button', isSaving, onSubmit }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <button
        type={type}
        className={`w-auto ${isMobile ? 'w-full' : 'min-w-[189px]'} ${
          !disabled && 'pointer-events-none cursor-not-allowed '
        }`}
        onClick={onSubmit}
      >
        <div
          className={`
                ${disabled ? 'bg-baseBlue500' : 'bg-grayTone100'} 
                 ${disabled && 'shadow-lg'} 
                    ${disabled ? 'text-white' : 'text-grayTone500'} 
                    ${isMobile ? 'px-4' : 'pl-10 pr-12'}
                my-0 w-full rounded-xl py-4 max-w-[540px] h-[58px] text-center cursor-pointer font-bold align-middle`}
        >
          {isSaving ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              {icon && <i className="fa-solid fa-arrow-right pr-2" />}
              {text}
            </>
          )}
        </div>
      </button>
    </>
  );
};
