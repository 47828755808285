import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';

const BlogCard = ({
  title,
  description,
  image,
  tip,
}: {
  title: string;
  description: string;
  image: string;
  tip: string;
}) => {
  const isMobile = useIsMobile();
  return (
    <div className={`${isMobile ? '' : ' min-h-[436px]'} home-card-container bg-white`}>
      <div className="relative">
        <img src={image} className="object-cover h-52 w-full rounded-2xl" alt="" />
        <div className="absolute top-10 -left-3 transform -translate-y-1/2 bg-baseBlue500 p-2 w-[94px] text-white font-bold rounded-lg text-center shadow-lg">
          {tip}
        </div>
      </div>
      <div className="p-4">
        <h4 className="h4 text-baseBlue500">{title}</h4>
        <div>
          <div className="flex items-center">
            <p className="regular-text text-baseTonBlue900">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
