import React from 'react';
import Hero from '../Hero';
import { useLocation } from 'react-router-dom';

const SuccessActivityForm = () => {
  const location = useLocation();
  const name = location.state?.name;

  return (
    <div>
      {/* Hero Image */}
      <Hero image="Experience-41.png" heading="Activiteit toegevoegd" place="Vitality Experience 2022, Amsterdam"/>

      <div className="px-[4rem] py-[2rem]">
        <h2 className="h2 pb-6">Gefeliciteerd 🥳</h2>
        <h6 className="h6 w-1/2">Je activiteit <span className="text-baseBlue500">{name}</span> is gepubliceerd! Kom regelmatig terug om te kijken hoe je activiteit het doet en om te reageren op reacties en vragen van kluppers.</h6>
      </div>
    </div>
      /*}

     */
  );
};

export default SuccessActivityForm;
