import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef } from 'react';

interface SearchPlaceProps {
  onPlaceChange: (place: google.maps.places.PlaceResult) => void;
  showLabel?: boolean;
  errorState?: boolean | '';
  initialValue?: string;
}

const SearchPlace: React.FC<SearchPlaceProps> = ({ onPlaceChange, showLabel, errorState, initialValue }) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    if (autocompleteRef.current != null) {
      const place = autocompleteRef.current.getPlace();

      if (place?.place_id) {
        place?.place_id && onPlaceChange(place);
      }
    }
  };

  useEffect(() => {
    return () => {
      autocompleteRef.current = null;
    };
  }, []);

  return (
    <div className="flex flex-col w-full gap-y-1">
      {showLabel && (
        <label htmlFor="location" className="text-[14px] text-tertiary pl-4 font-light">
          Plaats
        </label>
      )}
      {isLoaded && (
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          options={{ fields: ['place_id', 'geometry', 'formatted_address'] }}
        >
          <input
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
            type="text"
            name="location"
            id="autocomplete"
            className={`border ${
              errorState ? 'border-accent-secondary' : 'border-accent-primary'
            } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
            placeholder="Kies je locatie"
            defaultValue={initialValue}
          />
        </Autocomplete>
      )}
    </div>
  );
};

export default SearchPlace;
