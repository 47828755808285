import { useNavigate } from 'react-router-dom';
import MenuIcon from '../../assets/svg/burger-icon.svg';
import { IPartner } from '../../modals/interfaces';

export const Header: React.FC<{ partner: IPartner | undefined; onClickMenuButton: () => void }> = ({
  partner,
  onClickMenuButton,
}) => {
  const navigate = useNavigate();

  const handleOnClickHome = () => {
    navigate('/');
  };

  const handleOnClickProfilePicture = () => {
    navigate('/profile');
  };

  return (
    <div className="bg-baseTonBlue900 h-[70px] w-full sticky top-0 z-10 flex justify-between items-center px-3 py-3">
      <div className="flex items-center justify-center gap-x-2 cursor-pointer" onClick={handleOnClickProfilePicture}>
        <img className="w-[45px] h-[45px] rounded-full" src={partner?.photo} alt="partner photo" />
      </div>
      <div className="text-white font-bold text-2xl cursor-pointer" onClick={handleOnClickHome}>
        Partner Portal
      </div>
      <div onClick={onClickMenuButton} style={{ cursor: 'pointer' }}>
        <img alt="menu-button" width={50} height={50} src={MenuIcon} />
      </div>
    </div>
  );
};
