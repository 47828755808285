import { FC, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
// @ts-ignore
import KulpLogo from '../assets/img/klup-logo.png';
// @ts-ignore
import VitalityExperienceLogo from '../assets/img/vitality-experience-logo.png';
import FormControl from './FormControl';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchPlace from './SearchPlace';
import { useIsMobile } from '../hooks/useIsMobile';

const TextErrorMessage = (props: any) => {
  return <p className="note-text-light pl-4 pt-2">{props.children}</p>;
};

const CustomInputComponent = (props: any) => (
  <>
    <input
      className={`border ${
        props.errorstate ? 'border-accent-secondary' : 'border-accent-primary'
      } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
      type={props.type}
      {...props}
    />
  </>
);

export const InputField = (props: any) => {
  const { label, name, type, errorState, ...rest } = props;
  return (
    <div>
      {label && (
        <label className="note-text-light text-baseTonBlue900 pl-4" htmlFor={name}>
          {label}
        </label>
      )}
      <Field type={type} name={name} id={name} as={CustomInputComponent} errorstate={errorState} {...rest} />
      <ErrorMessage name={name} component={TextErrorMessage} />
    </div>
  );
};

interface ButtonProps {
  disabled?: boolean;
  text: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

export const SubmitButton: FC<ButtonProps> = ({ disabled, text, onClick, type = 'button' }) => {
  return (
    <>
      <button type={type} className="w-full" onClick={onClick} disabled={disabled}>
        <div
          className={`
              ${disabled ? 'bg-grayTone100' : 'bg-baseBlue500'} 
               ${!disabled && 'shadow-lg'} 
                  ${disabled ? 'text-grayTone500' : 'text-white'} 
              my-4 w-full rounded-xl py-4 px-4 max-w-[540px] h-[58px] text-center cursor-pointer font-bold `}
        >
          <i className="fa-solid fa-arrow-right pr-2" />
          {text}
        </div>
      </button>
    </>
  );
};

const RegisterPartnerForm = () => {
  const isMobile = useIsMobile();
  const [completeRegistration, setCompleteRegistration] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('fa-solid fa-eye-slash');

  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password');
  const [iconConfirmPassword, setIconConfirmPassword] = useState('fa-solid fa-eye-slash');

  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_API_URL}register`;

  const formik = useFormik({
    initialValues: {
      companyName: '',
      firstName: '',
      lastName: '',
      description: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      type: '',
      location: '',
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required('Dit veld is nog leeg'),
      firstName: Yup.string()
        .required('Dit veld is nog leeg')
        .matches(/^[aA-zZ\s]+$/, 'Je naam moet uit standaard letters bestaan '),
      lastName: Yup.string()
        .required('Dit veld is nog leeg')
        .matches(/^[aA-zZ\s]+$/, 'Je naam moet uit standaard letters bestaan '),
      email: Yup.string()
        .email('Dit is geen correct emailadres')
        .required('Je naam moet uit standaard letters bestaan')
        .test('checkEmail', 'Er is al een profiel met dit e-mailadres', async (email) => {
          if (email) {
            const exist = await checkEmailExist(email);
            return !exist;
          }
          return true;
        }),
      phoneNumber: Yup.number().required('Dit veld is nog leeg').typeError('Dit is geen correct telefoonnummer'),
      password: Yup.string()
        .required('Dit veld is nog leeg')
        .min(6, 'Het wachtwoord moet minimaal 6 tekens lang zijn.') // Minimum length of 6 characters

        // Must contain at least one lowercase letter
        .matches(/[a-z]/, 'Het wachtwoord moet ten minste één kleine letter bevatten.')

        // Must contain at least one uppercase letter
        .matches(/[A-Z]/, 'Het wachtwoord moet ten minste één hoofdletter bevatten.')

        // Must contain at least one digit
        .matches(/[0-9]/, 'Het wachtwoord moet ten minste één cijfer bevatten.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Je wachtwoord komt niet overeen')
        .required('Dit veld is nog leeg'),
      type: Yup.string().required('Je hebt nog geen partner type geselecteerd'),
      location: Yup.string().required('Dit veld is nog leeg'),
    }),
    onSubmit: (values) => {},
  });

  const checkEmailExist = async (email: string) => {
    const url = `${process.env.REACT_APP_API_URL}email/verification`;

    try {
      axios.defaults.withCredentials = true;

      const res: AxiosResponse = await axios.post(url, { email });
      return res.data ? true : false;
    } catch (error) {
      const axiosError = error as AxiosError;
      return false;
    }
  };

  const handleRegisterForm = async () => {
    const { confirmPassword, ...restOfValues } = formik.values;

    try {
      const response = await axios.post(url, restOfValues, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response) {
        navigate('/success');
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
    }
  };

  const show = () => {
    type === 'password' ? setType('text') : setType('password');
    icon === 'fa-solid fa-eye' ? setIcon('fa-solid fa-eye-slash') : setIcon('fa-solid fa-eye');
  };

  const showConfirmPassword = () => {
    typeConfirmPassword === 'password' ? setTypeConfirmPassword('text') : setTypeConfirmPassword('password');
    iconConfirmPassword === 'fa-solid fa-eye'
      ? setIconConfirmPassword('fa-solid fa-eye-slash')
      : setIconConfirmPassword('fa-solid fa-eye');
  };

  const locationOptions = [
    {
      key: 1,
      value: 'Vitality Experience',
    },
    {
      key: 2,
      value: 'Klup Partner',
    },
  ];

  const radioOptions = [
    {
      key: 'Vitality Experience',
      value: 'VITALITY_EXPERIENCE',
      logo: VitalityExperienceLogo,
    },
    {
      key: 'Klup Partner',
      value: 'KLUP_PARTNER',
      logo: KulpLogo,
    },
  ];

  const handlePlaceChange = (selectedPlace: any) => {
    formik.setFieldValue('location', selectedPlace.formatted_address ?? selectedPlace);
  };

  return (
    <FormikProvider value={formik}>
      {!completeRegistration && (
        <section
          className={`${isMobile ? 'w-full mx-2' : 'h-[700px] max-w-[540px]'} container mx-auto grid content-center`}
        >
          <div className="mb-4">
            <h3 className="h3">Registreren als Partner</h3>
            <h6 className="h6">Welkom bij Klup!</h6>
          </div>
          <form onSubmit={formik.handleSubmit} className="flex flex-wrap max-w-[540px] justify-between gap-y-3">
            {/*Full Name*/}
            <div className="flex flex-col w-full gap-y-2">
              <InputField
                type="text"
                name="companyName"
                label="Bedrijfsnaam"
                errorstate={formik.errors.companyName && formik.touched.companyName}
                errorMessage={formik.errors.companyName}
              />
            </div>

            {/*First Name*/}
            <div className="flex flex-col w-[47%] gap-y-2">
              <InputField
                type="text"
                name="firstName"
                label="Voornaam contactpersoon"
                errorstate={formik.errors.firstName && formik.touched.firstName}
                errorMessage={formik.errors.firstName}
              />
            </div>

            {/*Last Name*/}
            <div className="flex flex-col w-1/2 gap-y-2">
              <InputField
                type="text"
                name="lastName"
                label="Achternaam contactpersoon"
                errorstate={formik.errors.lastName && formik.touched.lastName}
                errorMessage={formik.errors.lastName}
              />
            </div>

            {/*Email*/}
            <div className="flex flex-col w-[47%] gap-y-2">
              <InputField
                type="email"
                name="email"
                label="Emailadres"
                errorstate={formik.errors.email && formik.touched.email}
                errorMessage={formik.errors.email}
              />
            </div>

            {/*Phone*/}
            <div className="flex flex-col w-1/2 gap-y-2">
              <InputField
                type="text"
                name="phoneNumber"
                label="Telefoonnummer"
                pattern="[0-9]{10}"
                maxLength={10}
                errorstate={formik.errors.phoneNumber && formik.touched.phoneNumber}
                errorMessage={formik.errors.phoneNumber}
              />
            </div>

            {/*location*/}
            <SearchPlace onPlaceChange={handlePlaceChange} showLabel />

            {/* Password */}
            <div className="relative w-full space-y-2">
              <div className="absolute right-3 top-14 grid -translate-y-1/2 place-items-center text-accent-primary">
                <i className={` ${icon} cursor-pointer text-tertiary`} onClick={show} aria-hidden="true"></i>
              </div>
              <div className="absolute right-10 top-12 grid -translate-y-1/2 place-items-center">
                {formik.touched.password && !formik.errors.password && (
                  <i className="fa fa-check-circle text-green-500" />
                )}
              </div>
              <label htmlFor="password" className="text-[14px] text-tertiary pl-4 font-light">
                Wachtwoord
              </label>
              <input
                type={type}
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Wachtwoord"
                className={`border ${
                  formik.errors.password && formik.touched.password
                    ? 'border-accent-secondary'
                    : 'border-accent-primary'
                } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
              />

              {((formik.touched.password && formik.errors.password) || !formik.touched.password) && (
                <div className="text-[13px] w-full pl-4">
                  Het wachtwoord moet minstens 6 tekens lang zijn en 1 kleine letter, 1 hoofdletter & 1 cijfer bevatten.
                </div>
              )}
            </div>

            {formik.errors.password && formik.touched.password && (
              <p className="text-accent-secondary font-light pl-4 text-sm">{formik.errors.password}</p>
            )}
            {/* .end Password */}

            {/*Confirm Password*/}
            <div className="relative w-full space-y-2">
              <div className="absolute right-3 top-14 grid -translate-y-1/2 place-items-center text-accent-primary">
                <i
                  className={`${iconConfirmPassword} cursor-pointer text-tertiary`}
                  onClick={showConfirmPassword}
                  aria-hidden="true"
                ></i>
              </div>
              <div className="absolute right-10 top-12 grid -translate-y-1/2 place-items-center">
                {formik.touched.confirmPassword && !formik.errors.confirmPassword && (
                  <i className="fa fa-check-circle text-green-500" />
                )}
              </div>
              <label htmlFor="password" className="text-[14px] text-tertiary pl-4 font-light">
                Herhaal wachtwoord
              </label>
              <input
                type={typeConfirmPassword}
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Herhaal wachtwoord"
                className={`border ${
                  formik.errors.confirmPassword && formik.touched.confirmPassword
                    ? 'border-accent-secondary'
                    : 'border-accent-primary'
                } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
              />
            </div>
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
              <p className="text-accent-secondary font-light pl-4 text-sm">{formik.errors.confirmPassword}</p>
            )}

            {/* .end Password */}

            {/*Partner Type*/}
            <div
              className={`flex ${
                isMobile ? 'flex-col' : 'flex-row  items-center'
              } justify-between w-full flex-nowrap space-y-2 mb-4`}
            >
              <p className="text-base text-tertiary font-normal tracking-tight">Partner type</p>

              <div className="flex gap-x-2">
                <FormControl control="radio" name="type" label="Partner type" options={radioOptions} />
              </div>
              {formik.errors.type && formik.touched.type && (
                <p className="text-accent-secondary font-light pl-4 text-sm">{formik.errors.type}</p>
              )}
            </div>

            <SubmitButton
              text="Door naar de laatste stap"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={() => setCompleteRegistration(true)}
            />

            <div className="flex w-full flex-col items-center justify-center">
              <p className="note-text-light text-baseTonBlue900 text-center">
                Door te registreren ga je akkoord met de
                <a href="#" className="text-baseBlue500 font-light">
                  {' '}
                  gebruikersvoorwaarden
                </a>
              </p>
              <p className="note-text-light text-baseTonBlue900 text-center">
                en
                <a href="#" className="text-baseBlue500 font-light">
                  {' '}
                  privacy policy{' '}
                </a>
                van Klup.
              </p>
            </div>
          </form>
        </section>
      )}
      {completeRegistration && (
        <section className="h-[700px] max-w-[540px] container mx-auto grid content-center">
          <div className="mb-4">
            <h3 className="h3">Registreren als Partner</h3>
            <h6 className="h6">
              Wie zijn jullie en wat voor activiteiten wil je aanbieden aan de Klup community tijdens de Vitality
              Experience?
            </h6>
          </div>
          <div className="container mx-auto mt-4">
            <label htmlFor="tekens" className="text-[14px] text-tertiary pl-4 pb-1 font-light">
              Wat voor activiteit(en) inclusief voordeel wil jij aanbieden?
            </label>
            <textarea
              id="tekens"
              name="description"
              onChange={(event) => {
                formik.setFieldValue('description', event.target.value);
              }}
              className={`border
        ${+formik.values.description.length > 500 ? 'border-accent-secondary' : 'border-accent-primary'} 
        ${+formik.values.description.length > 500 ? 'text-accent-secondary' : 'text-tertiary'}
        h-[129px] rounded-xl py-4 px-4 text-[17px]  w-full focus:outline-none border-1 overflow-y-hidden`}
            ></textarea>
          </div>

          <div className="relative float-end text-tertiary text-right">
            <span className={`${+formik.values.description.length > 500 ? 'text-accent-secondary' : 'text-tertiary'}`}>
              {+formik.values.description.length > 500 && <span>Maak je tekst korter! </span>}
              <span> {formik.values.description.length} &#47; 500 tekens</span>
            </span>
          </div>

          <SubmitButton
            text="Registratie afronden"
            type="submit"
            disabled={+formik.values.description.length > 500 || +formik.values.description.length == 0}
            onClick={handleRegisterForm}
          />
        </section>
      )}
    </FormikProvider>
  );
};
export default RegisterPartnerForm;
