import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import axios, { AxiosError } from 'axios';
import { TOKEN_NAME } from '../utils/helpers';
import { NavLink, useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('fa-solid fa-eye-slash');
  const [error, setError] = useState<string | null>(null);

  const url = `${process.env.REACT_APP_API_URL}login`;

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().email('Dit is geen correct emailadres').required('Dit veld is nog leeg'),
      password: Yup.string().required('Dit veld is nog leeg'),
    }),
    onSubmit: (values) => {
      loginRequest(values);
    },
  });

  const show = () => {
    type === 'password' ? setType('text') : setType('password');
    icon === 'fa-solid fa-eye' ? setIcon('fa-solid fa-eye-slash') : setIcon('fa-solid fa-eye');
  };

  const loginRequest = async (payload: any) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('username', payload.username);
    data.append('password', payload.password);

    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post(url, data);

      if (response) {
        window.localStorage.setItem(TOKEN_NAME, JSON.stringify(response.data));
        navigate('/home');
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      // formik.setErrors({
      //   username: 'We kunnen geen gebruiker vinden met deze combinatie van emailadres en/of wachtwoord.',
      // });
      formik.setStatus({
        unauthorized: 'We kunnen geen gebruiker vinden met deze combinatie van emailadres en/of wachtwoord.',
      });
      if (axiosError.response) {
        // setError(axiosError.response.data as string);
      } else {
        // setError(axiosError.message);
      }
      console.log('error: ', err);
    }
  };

  return (
    <FormikProvider value={formik}>
      <section className="h-[700px] max-w-[540px] container mx-auto grid content-center">
        <div className="mb-8">
          <h3 className="h3">Inloggen voor Klup Partners</h3>
          <h6 className="h6">Welkom terug!</h6>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-wrap max-w-[540px] justify-between gap-y-3">
          {/* Email */}
          <div className="flex flex-col w-full gap-y-2">
            <label htmlFor="username" className="text-[14px] text-tertiary pl-4 font-light">
              E-mailadres
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Emailadres"
              className={`border ${
                (formik.errors.username && formik.touched.username) || error
                  ? 'border-accent-secondary'
                  : 'border-accent-primary'
              } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
            />
            {/* {formik.errors.username && formik.touched.username && (
              <p className="text-accent-secondary font-light pl-4 text-sm">{formik.errors.username}</p>
            )} */}
            {/* {error && (
              <p className="text-accent-secondary font-light pl-4 text-sm">
                Emailadres niet gevonden, controleer de spelling
              </p>
            )} */}
          </div>

          {/* Password */}
          <div className="relative w-full space-y-2">
            <div className="absolute right-3 top-14 grid -translate-y-1/2 place-items-center text-accent-primary">
              <i className={` ${icon} cursor-pointer text-tertiary`} onClick={show} aria-hidden="true"></i>
            </div>
            <label htmlFor="password" className="text-[14px] text-tertiary pl-4 font-light">
              Wachtwoord
            </label>
            <input
              type={type}
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Wachtwoord"
              className={`border ${
                (formik.errors.password && formik.touched.password) || error
                  ? 'border-accent-secondary'
                  : 'border-accent-primary'
              } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
            />
          </div>
          {formik.status?.unauthorized && (
            <p className="text-accent-secondary font-light pl-4 text-sm">{formik.status.unauthorized}</p>
          )}

          {/*Remember Password*/}
          <div className="flex w-full justify-end gap-x-4 mt-4">
            <p className="text-tertiary">Onthoud wachtwoord</p>

            <input
              type="checkbox"
              className="w-5 bg-secondary cursor-pointer"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/*SUBMIT Button*/}
          <button type="submit" className="w-full" disabled={!formik.isValid}>
            <div
              className={`${
                formik.dirty && formik.isValid ? '' : 'opacity-30'
              } mt-5 w-full bg-secondary border border-accent-primary rounded-xl py-4 px-4 text-white max-w-[540px] h-[58px] focus:outline-none border-1 focus:border-blue-700 text-center cursor-pointer shadow-lg`}
            >
              <i className="fa-solid fa-arrow-right pr-2" />
              Inloggen
            </div>
          </button>

          <div className="flex w-full items-center justify-center flex-col gap-y-8 mt-4">
            <p className="text-base text-center">
              <a href="#" className="underline text-secondary font-normal">
                {' '}
                Wachtwoord vergeten?
              </a>
            </p>

            <p className="text-base text-tertiary font-normal text-center">
              Ben je nog geen Partner?
              <br />
              <NavLink to="/register">
                <span className="underline text-secondary font-normal"> Vul het Klup Partner-formulier in!</span>
              </NavLink>
            </p>
          </div>
        </form>
      </section>
    </FormikProvider>
  );
};

export default LoginForm;
