import React from 'react';
import { useIsMobile } from '../hooks/useIsMobile';

const Hero = ({
  image,
  heading,
  place,
  reverse,
}: {
  image: string;
  heading: string;
  place: string;
  reverse?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <div className="relative">
      <img
        className={`${reverse && 'flip-horizontal'} w-screen max-h-[330px] object-cover`}
        src={`/images/${image}`}
        alt={heading}
      />

      <div className="absolute bottom-0 w-full h-1/2 bg-gradient-to-b from-transparent via-transparent to-black opacity-90"></div>

      <div className={`absolute ${isMobile ? 'bottom-[5%]' : 'bottom-[15%]'}  left-[5%]`}>
        <h2 className="h2 text-white ">{heading}</h2>
      </div>

      {!isMobile && (
        <div className="absolute top-[85%] left-[80%]">
          <p className="note-text-light text-white">{place}</p>
        </div>
      )}
    </div>
  );
};

export default Hero;
