import { axiosInstance } from '../axios';

export const markAsReadAPI = async ({ notificationIds }: { notificationIds: number[] | undefined }) => {
  const url = `${process.env.REACT_APP_API_URL}api/notification/read`;

  return axiosInstance.request({
    method: 'post',
    url: url,
    data: {
      notificationIds,
    },
    headers: { 'Content-Type': 'application/json' },
  });
};
