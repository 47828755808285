import axios, { AxiosError } from 'axios';
import { TOKEN_NAME } from '../utils/helpers';

export const LogoutRequest = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}logout`;
    try {
        axios.defaults.withCredentials = true;

        await axios.request({
            method: "post",
            url: url,
            headers: { "Content-Type": "application/json" },
        });

        localStorage.removeItem(TOKEN_NAME);
    } catch (err) {
        const axiosError = err as AxiosError;
        if (axiosError.response) {
            console.error(axiosError.response.data);
        } else {
            console.error(axiosError.message);
        }
    }
};
