export const CancelledChip = ({ cancelled }: { cancelled?: boolean | null }) => {
  return (
    <>
      {cancelled && (
        <div className="w-[120px] h-[32px] mt-[-4px] rounded-lg bg-soRed flex items-center justify-center py-2 px-2">
          <p className="text-pureWhite font-bold text-sm" style={{ lineHeight: '18px' }}>
            Geannuleerd
          </p>
        </div>
      )}
    </>
  );
};
