import { ErrorResponse, SuccessResponse } from '../../api/model';
import { markAsReadAPI } from '../../api/notification/notification.api';
import { createErrorResponse, createSuccessResponse } from '../../api/helpers';

export const markActivityNotificationAsRead = async (
  notificationId: number
): Promise<SuccessResponse<any> | ErrorResponse> => {
  return await markAsReadAPI({ notificationIds: [notificationId] })
    .then((res) => createSuccessResponse(res.data))
    .catch((error: any) => {
      return createErrorResponse(error?.message ?? '');
    });
};
