import React, { useEffect, useMemo } from 'react';
import { isLoggedIn } from './utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  let navigate = useNavigate();
  let location = useLocation();

  const token = useMemo(() => isLoggedIn(), [navigate, location]);

  useEffect(() => {
    if (!token) {
      navigate("/login", { state: { from: location.pathname } });
    }
  }, [navigate, location, token]);

  return token ? children : null;
};

export default PrivateRoute;
