import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutRequest } from '../../services/LogoutRequest';
import { useIsMobile } from '../../hooks/useIsMobile';

const Logout = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    await LogoutRequest();
    navigate('/');
  };

  return (
    <div className={`${isMobile ? 'p-4 py-6' : ' max-h-[245px] p-8'} bg-white rounded-2xl w-full space-y-3 shadow-lg`}>
      <h2 className="h4 text-tertiary font-bold">Uitloggen</h2>
      <p className="regular-text text-tertiary">
        Geef ons toestemming om je inloggegevens op te slaan, zodat je deze niet opnieuw hoeft in te vullen en is de
        Partner Portal makkelijker toegankelijk voor jou.
      </p>

      <button
        className="flex items-center justify-center w-[106px] h-[48px] bg-none border-2 border-grayTone700 text-grayTone700 my-4 rounded-xl py-4 px-4 text-center cursor-pointer font-bold "
        onClick={handleLogout}
      >
        Afmelden
      </button>
    </div>
  );
};

export default Logout;
