import React from 'react';
import { Field } from 'formik';
// @ts-ignore
import VitalityExperienceLogo from '../assets/img/vitality-experience-logo.png';

const RadioButtons = (props: any) => {
  const { label, name, options, ...rest } = props;

  return (
    <>
        <label></label>
      <Field name={name} {...rest}>
        {/* @ts-ignore*/}
        {({ field }) => {
          return options.map((option: any) => {
            return (
              <React.Fragment key={option.key}>
                <div className="radio">
                  <label className={`flex items-center gap-x-5 text-lg border p-3 rounded-xl border-accent-primary text-tertiary ${field.value === option.value ? 'bg-accent-primary_hover' : 'bg-white' }`}>
                      <img
                          className="w-10 rounded-lg"
                          src={option.logo}
                          alt=""
                      />
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                      className={`${field.value === option.value} && bg-accent-primary_hover`}
                    />
                    {option.key}
                  </label>
                </div>
              </React.Fragment>
            );
          });
        }}
      </Field>
      {/*<ErrorMessage name={name} component={TextError} />*/}
    </>
  );
};

export default RadioButtons;
