type ProfileForm = {
  companyName: string;
  photo: string | null;
  photoFile: string | File | null;
  bgPhoto: string | null;
  bgPhotoFile: string | File | null;
  location: string;
  interests: number[];
  locationLat: number;
  locationLng: number;
  bio: string;
  phoneNumber: string;
};

export const formToProfileMapper = (form: any) => ({
  companyName: form.companyName,
  photo: form.photo,
  bgPhoto: form.bgPhoto,
  location: form.location,
  interests: form.interests,
  locationLat: form.locationLat,
  locationLng: form.locationLng,
  bio: form.bio,
  phoneNumber: form.phoneNumber,
});
