import { createErrorResponse, createSuccessResponse } from '../../api/helpers';
import { ErrorResponse, SuccessResponse } from '../../api/model';
import { markAsReadAPI } from '../../api/notification/notification.api';

export const markNotificationsAsRead = async ({
  notificationIds,
}: {
  notificationIds: number[] | undefined;
}): Promise<SuccessResponse<any> | ErrorResponse> => {
  return await markAsReadAPI({ notificationIds })
    .then((res) => createSuccessResponse(res.data))
    .catch((error: any) => {
      return createErrorResponse(error?.message ?? '');
    });
};
