import dayjs from 'dayjs';
import { Message } from './messages.model';

export type MessageItem = {
  item: Message;
  handleCheckboxChange?: any;
  handleMessageClick: any;
  unread?: boolean;
};

export const MessageItem: React.FC<MessageItem> = ({
  item,
  unread = false,
  handleCheckboxChange,
  handleMessageClick,
}) => {
  const isClickable = item?.inAppRedirection?.entityType === 'ACTIVITY';

  return (
    <div key={item.id} className="flex flex-row gap-x-2 items-start">
      {handleCheckboxChange && (
        <input
          id={`checkbox-${item.id}`}
          aria-describedby={`checkbox-${item.id}`}
          type="checkbox"
          className={`cursor-pointer bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded mt-[23px]`}
          onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
        />
      )}

      <div
        className={`flex relative space-x-3 items-start w-full ${isClickable ? 'cursor-pointer' : ''} hover:bg-baseBlue200 ease-in-out duration-300 rounded-lg p-2`}
        onClick={() => isClickable && handleMessageClick(item)}
      >
        <img
          src={
            item.photo ??
            'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png'
          }
          className="w-[46px] h-[46px] rounded-full"
        />
        {unread && (
          <span className="absolute z-10 top-10 left-8 transform -translate-y-1/2 w-3.5 h-3.5 bg-baseStateWarning500 border-2 border-white dark:border-gray-800 rounded-full"></span>
        )}
        <div className="max-w-[80%]">
          <p className="bold-text text-tertiary">{item?.message}</p>
          <p className="note-text-light text-gray04">{dayjs(item?.createDate).format('hh:mm MMMM D')}</p>
        </div>
      </div>
    </div>
  );
};
