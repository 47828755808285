import Select from "./Select";
import RadioButtons from './RadioButtons';
const FormControl = (props: any) => {
    const { control, ...rest } = props;
    switch (control) {
        case "select":
            return <Select {...rest} />;
        case "radio":
            return <RadioButtons {...rest} />;
        default:
            return null;
    }
};

export default FormControl;
