import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';

const Select = (props: any) => {
    const { name, label, options, ...rest } = props;

    return (
        <div>
            <label>{label}</label>
            <Field className="border-accent-primary border rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700" as="select" name={name} id={name} label={label} {...rest}>
                {options.map((option: any) => {
                    return (
                        <option key={option.key} value={option.key}>
                            {option.value}
                        </option>
                    );
                })}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

export default Select;
