import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Message } from '../messages/messages.model';
import { MessageItem } from '../messages/messageItem';

interface MessagesCardProps {
  messages: Message[];
  profileCompleted: boolean;
}

const MessagesCard: FC<MessagesCardProps> = ({ messages, profileCompleted }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleMessageClick = (item: Message, markAsRead?: boolean) => {
    if (item?.inAppRedirection?.entityId && item?.inAppRedirection?.entityType === 'ACTIVITY') {
      navigate(`/activities/${item?.inAppRedirection?.entityId}`, {
        state: { notificationId: markAsRead ? item.id : undefined },
      });
    }
  };

  return (
    <div className="home-card-container flex flex-col justify-between p-6 bg-white">
      <div>
        <div className="relative">
          <h4 className="h4 text-tertiary pb-5">Meldingen</h4>
          <span className="absolute top-0 left-[7.4rem] transform -translate-y-1/2 w-3.5 h-3.5 bg-baseStateWarning500 border-2 border-white dark:border-gray-800 rounded-full"></span>
        </div>

        {messages.length > 0 ? (
          <div className="flex-col space-y-2">
            {messages.map((message, id: number) => (
              <MessageItem item={message} key={id} handleMessageClick={() => handleMessageClick(message, true)} />
            ))}
          </div>
        ) : (
          <div className="flex-col space-y-2">
            <div className="flex flex-col items-center">
              <p className="regular-text text-tertiary place-self-start">Je hebt geen ongelezen meldingen</p>
              <img src="/icons/meldingen_icon.jpg" className="max-w-[133px]" />
            </div>
          </div>
        )}
      </div>

      <Link to="/messages" className={`${!profileCompleted && 'pointer-events-none opacity-20 cursor-not-allowed'}`}>
        <div className="flex items-center">
          <i className="fa-solid fa-arrow-right pr-2 text-baseBlue500" />
          <p className="text-baseBlue500 font-bold text-lg">
            {messages.length > 0 ? 'Bekijk alle meldingen' : 'Naar meldingen'}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default MessagesCard;
