import React, { useEffect, useState } from 'react';
import Hero from './Hero';
import axios, { AxiosError } from 'axios';
import { useIsMobile } from '../hooks/useIsMobile';

interface Notification {
  notificationSettingsId: number;
  description: string;
  enabled: boolean;
}

interface ProfileSettings {
  visiblePhone: boolean;
  visibleEmail: boolean;
}

const Settings = () => {
  const isMobile = useIsMobile();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [profileSettings, setProfileSettings] = useState<ProfileSettings>({ visiblePhone: false, visibleEmail: true });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getPartnerSettings = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/partner/settings`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setProfileSettings(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getPartnerSettings();
  }, []);

  const updateProfileSettings = async (updatedProfileSettings: ProfileSettings) => {
    const url = `${process.env.REACT_APP_API_URL}api/partner/settings`;

    try {
      axios.defaults.withCredentials = true;

      const res = await axios.put(url, updatedProfileSettings, {
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    const getPartnerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/notification/settings`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setNotifications(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getPartnerData();
  }, []);

  const updateNotification = (id: number) => {    
    const url = `${process.env.REACT_APP_API_URL}api/notification/settings/${id}`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'put',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const toggleNotification = (id: number) => {
    const updatedNotifications = notifications.map((notification) => {
      if (notification.notificationSettingsId === id) {
        return { ...notification, enabled: !notification.enabled };
      }
      return notification;
    });
    setNotifications(updatedNotifications);
    updateNotification(id);
  };

  const toggleProfileSettings = (field: keyof ProfileSettings) => {
    const updatedProfileSettings = { ...profileSettings, [field]: !profileSettings[field] };
    setProfileSettings(updatedProfileSettings);
    updateProfileSettings(updatedProfileSettings);
  };

  return (
    <div>
      {/* Hero Image */}
      <Hero
        image="Fotografie-Vitality-Experience-29.png"
        heading="Instellingen"
        place="Vitality Experience 2022, Amsterdam"
      />

      <div className={`${isMobile ? 'px-6 py-12' : 'px-[4rem] py-[2rem]'}`}>
        <div className={`flex ${isMobile ? 'w-full' : 'w-[580px]'} flex-col  h-auto rounded-xl p-1 my-8 space-y-4`}>
          {/*
            <h4 className="h4 text-tertiary">Profiel </h4>
            <p className="regular-text text-tertiary">
              Het is verplicht om één van deze opties aan te zetten, zodat kluppers je kunnen bereiken.
            </p>

            <div className="flex w-full items-center justify-between">
              <h2 className="subtitle-extra-bold-text">
                Toon telefoonnummer op je profiel
              </h2>
              <div className="toggle flex">
                <label htmlFor="profileSettings_visibleEmail">
                  <input id="profileSettings_visibleEmail" type="checkbox" name="visibleEmail"
                         checked={profileSettings?.visibleEmail}
                         onChange={() => toggleProfileSettings('visibleEmail')}/>
                </label>
              </div>
            </div>

            <div className="flex w-full items-center justify-between">
              <h2 className="subtitle-extra-bold-text">
                Toon emailadres op je profiel
              </h2>
              <div className="toggle flex">
                <label htmlFor="profileSettings_visiblePhone">
                  <input id="profileSettings_visiblePhone" type="checkbox" name="visiblePhone"
                         checked={profileSettings?.visiblePhone}
                         onChange={() => toggleProfileSettings('visiblePhone')}/>
                </label>
              </div>
            </div>
            */}

          <h4 className="h4 text-tertiary">E-mail</h4>
          <p className="regular-text text-tertiary">Beheer je instellingen voor het ontvangen van mails.</p>

          {notifications.length > 0 &&
            notifications.map((item, index) => (
              <div key={index} className="flex w-full items-center justify-between">
                <h2 className="subtitle-extra-bold-text">{item.description}</h2>
                <div className="toggle flex">
                  <label htmlFor={`checkbox_${item.notificationSettingsId}`}>
                    <input
                      id={`checkbox_${item.notificationSettingsId}`}
                      type="checkbox"
                      name="no_end_time"
                      checked={item.enabled}
                      onChange={() => toggleNotification(item.notificationSettingsId)}
                    />
                  </label>
                </div>
              </div>
            ))}

          {/*
          <button>
            <div className="bg-baseBlue500 shadow-lg text-white my-4 rounded-xl py-4 px-4 h-[58px] w-[180px] text-center cursor-pointer font-bold">
              <i className="fa-solid fa-arrow-right pr-2" />
              Opslaan
            </div>
          </button>
          */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
