import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center p-6 mt-8">
      <img
        className="mb-4 self-center max-w-[216px] h-[77px] rounded-lg shadow-xl"
        src="/images/vitality-experience-white.png"
        alt=""
        onClick={() => navigate('/')}
      />

      <p className="text-sm text-baseTonBlue900 font-light text-center">
        Vitality Experience is een initiatief van Klup en Hersenstichting
      </p>
    </div>
  );
};
