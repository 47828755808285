import { ErrorResponse, SuccessResponse } from './model';

export const createSuccessResponse = <T>(data: T): SuccessResponse<T> => {
  return {
    success: true,
    message: 'Success',
    data,
  };
};

export const createErrorResponse = (message: string): ErrorResponse => {
  return {
    success: false,
    message,
  };
};
