export const interestsItems = [
  {
    id: 41,
    name: 'Kunst & Cultuur',
    active: true,
  },
  {
    id: 42,
    name: 'Borrelen',
    active: true,
  },
  {
    id: 44,
    name: 'Reizen',
    active: true,
  },
  {
    id: 45,
    name: 'Uit eten',
    active: true,
  },
  {
    id: 50,
    name: 'Wandelen',
    active: true,
  },
  {
    id: 51,
    name: 'Film',
    active: true,
  },
  {
    id: 55,
    name: 'Overig',
    active: true,
  },
  {
    id: 56,
    name: 'Dansen',
    active: true,
  },
  {
    id: 57,
    name: 'Fietsen',
    active: true,
  },
  {
    id: 58,
    name: 'Workshop',
    active: true,
  },
];
