import React from 'react';
import Hero from '../Hero';
import { AccordionCustomIcon } from '../Accordion';
import { useIsMobile } from '../../hooks/useIsMobile';

const Support = () => {
  const isMobile = useIsMobile();

  return (
    <div>
      {/* Hero Image */}
      <Hero image="578A0251.png" heading="Support" place="Vitality Experience 2023, Utrecht" />

      <div
        className={`${
          isMobile ? 'flex flex-col space-y-6 px-6 py-12' : 'flex flex-row cols-2 px-[4rem] py-[4rem]'
        }  gap-x-16`}
      >
        <div className={`${isMobile ? 'w-full' : 'w-2/3'} `}>
          <h4 className="h4 text-tertiary pb-2">FAQ</h4>
          <h6 className="h6 w-full pb-4">
            Check hier de meest gestelde vragen. Hopelijk staat je antwoord er tussen. Kom je er echt niet uit? Neem dan
            contact met ons op.
          </h6>

          <AccordionCustomIcon />
        </div>

        <div
          className={` ${
            isMobile ? '' : 'w-1/3 max-h-[340px] min-w-[200px]'
          }  justify-start bg-white shadow-lg px-4 py-5 rounded-2xl`}
        >
          <div>
            <h4 className="h4 text-tertiary pb-2">Contact</h4>
          </div>
          <div>
            <h6 className="h6">
              Voor vragen kun je mailen naar <a href="mailto:partners@kluppen.nl">partners@kluppen.nl</a> of bellen met{' '}
              <a href="tel:0202615781">020 261 5781</a>. We proberen je zo snel mogelijk te helpen.
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
