import CloseIcon from '../assets/svg/close-icon.svg';

export const Drawer: React.FC<{ open: boolean; onClose: () => void; children: any }> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <div
      className={`fixed top-0 right-0 z-20 w-full h-full transition-transform duration-500 transform ${
        open ? 'translate-y-0' : '-translate-y-[1000px]'
      }`}
    >
      <div className="w-full h-full">
        <div className="absolute bg-transparent top-5 right-5 cursor-pointer z-10" onClick={onClose}>
          <img width={50} height={50} src={CloseIcon}></img>
        </div>
        {children}
        {/* <h2 className="text-lg font-semibold">Drawer</h2>
      <p className="text-gray-500">This is a drawer.</p> */}
      </div>
    </div>
  );
};
